import React, { useEffect } from "react"
import SEO from "../seo"
import Footer from "../Footer"
import styles from "./../../scss/pages/_entradaslolla.module.scss"

import logo from "./../../images/Esports/logo-bud.svg"
import { Container } from "react-bootstrap"

export default function Success() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div styles={{ overflow: "hidden" }}>
      <SEO title="Entradas Lolla 2022" />
      <Container fluid className="m-0 p-0">
        <section>
          <div className={styles.wrap}>
            <div className={styles.backgroundCol}>
              <div className={styles.container}>
                <div>
                  <img src={logo} className={styles.logo} />
                </div>
                <div className={styles.wrapText}>
                  <div className={styles.titleContainer}>
                    <h1 className={styles.title}>GRACIAS!</h1>
                  </div>
                  <h1 className={styles.infoTextOne}>
                    El 15/3 anunciamos a los ganadores.
                    <br /> THE BIRRA BEHIND THE MUSIC
                  </h1>
                </div>
                <div>
                  <h1 className={styles.infoTextTwo}>
                    Seguinos en Instagram y enterate de <br />
                    todas las novedades
                  </h1>
                  <a
                    className={styles.btnContainer}
                    target="_blank"
                    href="https://www.instagram.com/budargentina/"
                  >
                    <div className={styles.followBtn}>Seguirnos</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.footer}>
          <Footer />
        </div>
      </Container>
    </div>
  )
}
